<template>
  <login-layout
    page-class="forgot-password"
    pageTitle="Reset Password"
    icon="user"
  >
    <div class="form-block-wrapper">
      <VeeForm
        @submit="submitForm"
        ref="editConnectorForm"
        v-slot="{ errors }"
        class="form"
        novalidate
      >
        <transition name="fade">
          <div class="server-error error-message" v-if="errorMessage">
            {{ errorMessage }}
          </div>
        </transition>
        <div class="form-group input">
          <Field
            name="password"
            as="input"
            type="password"
            rules="required|min:6"
            placeholder="New Password"
            v-model="password"
            :disabled="submitting"
          />
          <span class="error-message" v-if="errors.password">
            {{ errors.password }}
          </span>
        </div>
        <div class="form-group input">
          <Field
            name="password_confirmation"
            as="input"
            type="password"
            rules="required|confirmed:@password"
            placeholder="Repeat Password"
            :disabled="submitting"
          />
          <span class="error-message" v-if="errors.password_confirmation">
            {{ errors.password_confirmation }}
          </span>
        </div>
        <div class="form-group submit">
          <button class="btn primary" type="submit">Reset Password</button>
        </div>
        <div class="form-group">
          <router-link to="/login" class="link">
            Have an account? <span>Log in</span>
          </router-link>
        </div>
      </VeeForm>
    </div>
  </login-layout>
</template>

<script>
import LoginLayout from "@/layouts/LoginLayout";
import { mapActions, mapState } from "vuex";
import { getAccessToken } from "@/services/http-service";
export default {
  name: "reset-password",
  data() {
    return {
      token: null,
      password: ""
    };
  },
  computed: {
    ...mapState("auth", ["errorMessage"])
  },
  created() {
    if (!getAccessToken()) {
      const search = window.location.search
        .replace("?redirect=/", "")
        .replace("?", "")
        .split("&");

      // check if searh has length and first element is not empty
      if (search.length && search[0]) {
        const token = search.find(element => element.indexOf("token") !== -1);

        if (token) {
          const tokenValue = token.split("=")[1];
          if (tokenValue) {
            this.token = tokenValue;
          }
        }
      }
    }
  },
  methods: {
    ...mapActions("auth", ["passwordReset"]),
    submitForm(values) {
      this.$store.commit("auth/setErrorMessage", null, { root: true });
      if (values) {
        this.passwordReset({
          password: values.password,
          password_confirmation: values.password_confirmation,
          token: this.token
        });
      }
    }
  },
  components: {
    LoginLayout
  }
};
</script>
